.mobile-menu-is-open {
    overflow-y: hidden;
}

.main-menu-mobile {
    position: fixed;
    top: 82px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: z(modal);
    background-color: #fff;
    color: #fff;
    padding: 10%;
    overflow: auto;
    transform: translate3d(100%, 0, 0);
    background-color: get-color(secondary);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    transition: 0.3s;

    @include media(get-bp(desktop)) {
        display: none;
    }

    .menu-container__listing{
        display: block;

        >li{
            margin-bottom: 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .submenu{
                display: grid;
                visibility: hidden;
                opacity: 0;
                max-height: 0;
                padding: 0;
                margin: 0;
                width: 100%;
                list-style: none;
                row-gap: 10px;
                border-top: 1px solid rgba(get-color(primary), .1);
                transition: 400ms;

                &::-webkit-scrollbar {
                    width: 5px;
                }
                &::-webkit-scrollbar-track {
                    background: #f1f1f1; 
                }
                &::-webkit-scrollbar-thumb {
                    background: get-color(primary); 
                }
                &::-webkit-scrollbar-thumb:hover {
                    background-color: get-color(secondary);
                }
            }
            &.is-active{
                .submenu{
                    padding: 10px 0 0 0;
                    margin: 10px 0 0 0;
                    visibility: visible;
                    opacity: 100%;
                    max-height: 200px;
                    overflow: auto;
                }
                .js-open-submenu-mobile{
                    // transform: rotate(180deg);
                }
            }

            a {
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 400;
                line-height: 19.2px;
                text-align: left;
                color: get-color(primary);
            }
        }
    }

    .menu-item{
        margin-bottom: 20px;
        a{
            display: block;
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .menu-item__submenu{
            a{
                font-size: 18px;
            }
        }
    }


    &.is-active {
        transform: translate3d(0, 0, 0);
    }
}

.mobile-logo-img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}