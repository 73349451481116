.page-services {
    .services {
        padding: 2rem 0;

        @include media (get-bp(desktop)) {
            padding: 2rem 0;
        }
    }

    .services__item {
        padding: 6rem 0;

        &:nth-child(even) {
            background-color: rgba(245, 245, 245, 1);

            .container {
                flex-direction: row-reverse;
            }
        }

        .container {
            @include media (get-bp(desktop)) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        img {
            width: 100%;
            display: block;
            object-fit: cover;
            border-radius: 6px;
            margin-top: 30px;

            @include media (get-bp(desktop)) {
                width: 600px;
                height: 400px;
                margin-top: 0;
            }
        }
    }

    .services__description {
        @include media (get-bp(desktop)) {
            width: 600px;
        }

        h3 {
            font-weight: 500;
            color: get-color(dark);
            font-size: 32px;
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 30px;

            @include media (get-bp(desktop)) {
                font-size: 20px;
            }
        }

        a {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            padding: 1rem 2rem;
            border-radius: 24px;
            border: 1px solid get-color(primary);
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            transition: 400ms;

            &:hover {
                background-color: get-color(primary);
                color: get-color(light);

                i {
                    color: get-color(light);
                }
            }

            i {
                margin-left: 10px;
                font-size: 14px;
                margin-top: 1px;
                color: get-color(primary);
            }
        }
    }
}
