// Importe webfonts aqui.
//
// @example
//     @include import-font-face('Nome da fonte', '/caminho/da/fonte', $weight?, $style?);
//     @include import-font-face('Roboto', '../fonts/roboto/roboto');
//     @include import-font-face('Roboto', '../fonts/roboto/roboto-italic', normal, italic);
//     @include import-font-face('Roboto', '../fonts/roboto/roboto-bold', bold);
//     @include import-font-face('Roboto', '../fonts/roboto/roboto-bold-italic', bold, italic);

@include import-font-face('Gilroy', '../fonts/gilroy/gilroy-thin-webfont', 100);
@include import-font-face('Gilroy', '../fonts/gilroy/gilroy-thinitalic-webfont', 100, italic);

@include import-font-face('Gilroy', '../fonts/gilroy/gilroy-ultralight-webfont', 200);
@include import-font-face('Gilroy', '../fonts/gilroy/gilroy-ultralightitalic-webfont', 200, italic);

@include import-font-face('Gilroy', '../fonts/gilroy/gilroy-light-webfont', 300);
@include import-font-face('Gilroy', '../fonts/gilroy/gilroy-lightitalic-webfont', 300, italic);

@include import-font-face('Gilroy', '../fonts/gilroy/gilroy-regular-webfont', 400);
@include import-font-face('Gilroy', '../fonts/gilroy/gilroy-regularitalic-webfont', 400, italic);

@include import-font-face('Gilroy', '../fonts/gilroy/gilroy-medium-webfont', 500);
@include import-font-face('Gilroy', '../fonts/gilroy/gilroy-mediumitalic-webfont', 500, italic);

@include import-font-face('Gilroy', '../fonts/gilroy/gilroy-semibold-webfont', 600);
@include import-font-face('Gilroy', '../fonts/gilroy/gilroy-semibolditalic-webfont', 600, italic);

@include import-font-face('Gilroy', '../fonts/gilroy/gilroy-bold-webfont', 700);
@include import-font-face('Gilroy', '../fonts/gilroy/gilroy-bolditalic-webfont', 700, italic);

@include import-font-face('Gilroy', '../fonts/gilroy/gilroy-extrabold-webfont', 800);
@include import-font-face('Gilroy', '../fonts/gilroy/gilroy-extrabolditalic-webfont', 800, italic);

@include import-font-face('Gilroy', '../fonts/gilroy/gilroy-black-webfont', 900);
@include import-font-face('Gilroy', '../fonts/gilroy/gilroy-blackitalic-webfont', 900, italic);