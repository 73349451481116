.social-listing{
    display: flex;
    justify-content: center;
    gap: 16px;

    .social-listing__item{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: get-color(secondary);
        background-color: get-color(primary);
        height: 30px;
        width: 30px;
        border-radius: 100px;
        transition: 400ms;

        &:hover{
            transform: rotate(10deg);
        }
    }
}
