.mini-banner {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 18rem 0 8rem;

    @include media (get-bp(desktop)) {
        padding: 20rem 0 10rem;
    }

    .mini-banner__subtitle {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;

        p {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 16px;
            color: get-color(primary);
            margin-left: 10px;
        }
    }

    .mini-banner__title {
        h2 {
            font-weight: 400;
            font-size: 32px;
            color: get-color(light);

            strong, b {
                font-weight: 600;
            }
        }
    }
}
