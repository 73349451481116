.page-home {
    .banner {
        height: 700px;

        @include media(get-bp(tablet-portrait)) {
            height: 100vh;
        }

        .container {
            top: 190px;

            @include media(get-bp(tablet-portrait)) {
                top: 200px;
            }

            @include media(get-bp(desktop)) {
                top: 279px;
            }
        }

        .swiper-pagination {
            &.banner-pagination {
                display: flex;
                align-items: flex-end;
                justify-content: left;
                flex-direction: row;
                left: 20px;
                bottom: 0;
                width: 350px;
                overflow: scroll;

                &::-webkit-scrollbar {
                    width: 0px;
                    height: 5px;
                    background: transparent;
                }

                &::-webkit-scrollbar-track {
                    background: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background: get-color(primary);
                    border-radius: 10px;
                }

                @include media(get-bp(desktop)) {
                    width: 100%;
                }

                .swiper-pagination-bullet {
                    position: relative;
                    width: 310px;
                    height: 60px;
                    background: transparent;

                    @include media(get-bp(desktop)) {
                        height: 100px;
                    }

                    img {
                        display: block;
                        width: 100%;
                        object-fit: contain;
                        border-radius: 6px;
                        transition: 400ms;
                    }
                }

                .swiper-pagination-bullet-active {
                    background-color: transparent;

                    img {
                        transform: scale(1.2);
                    }
                }
            }
        }

        .banner__block {
            width: 335px;
            position: absolute;

            @include media(get-bp(tablet-portrait)) {
                width: 90%;
            }

            @include media(get-bp(desktop)) {
                width: 95%;
                padding: 0 2rem;
            }

            h3 {
                color: get-color(primary);
                font-size: 32px;
                font-weight: 400;
                line-height: 45px;
                text-align: center;
                text-shadow: 2px 2px 6px get-color(text);

                @include media(get-bp(desktop)) {
                    font-size: 60px;
                    line-height: 60px;
                }
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 16px;
                border-radius: 100px;
                border: 1px solid get-color(primary);
                padding: 11px 20px;
                width: fit-content;

                &:hover {
                    opacity: 0.9;
                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 1px;
                    color: get-color(light);
                }
            }
        }

        .banner-pagination {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .swiper-pagination-bullet {
            position: relative;
            width: 50px;
            height: 50px;
            margin: 5px;
            background: none;
            border: none;
        }

        .swiper-pagination-bullet img {
            width: 100%;
            height: auto;
            border-radius: 50%;
            border: 2px solid transparent;
        }

        .bullet-text {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            color: get-color(light);
            text-align: left;
            margin-top: 15px;
            width: 200px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;

            @include media(get-bp(desktop)) {
                font-size: 18px;
                width: 310px;
            }
        }

        .progress-bar {
            position: absolute;
            top: 0;
            left: 0;
            height: 5px;
            width: 0%;
            background: get-color(primary);
            transition: width 3s linear;
        }

        .banner__slider {
            position: relative;
            overflow: hidden;
        }

        .swiper-slide {
            position: relative;

            img {
                transition: transform 3s ease-in-out;
                transform: scale(1);
                z-index: 1;
                position: relative;

                &.zoom-in {
                    transform: scale(1.1);
                }
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(
                    to top,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.7) 100%
                );
                z-index: 2;
            }

            .banner__block {
                position: absolute;
                z-index: 3;
                opacity: 0;
                transform: translateY(100%);
                transition: opacity 1s ease, transform 1s ease;
            }

            &.swiper-slide-active {
                .banner__block {
                    opacity: 1;
                    transform: translateY(0);
                }
            }

            .banner-mobile {
                display: block;

                @include media(get-bp(tablet-portrait)) {
                    display: none;
                }
            }

            img {
                display: block;
                width: 100%;
                height: 700px;
                object-fit: cover;
                object-position: center;

                @include media(get-bp(tablet-portrait)) {
                    height: 750px;
                    object-position: 20% center;
                }

                @include media(get-bp(desktop)) {
                    height: 100vh;
                    object-position: center;
                }
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            background-image: none;
            color: get-color(primary);

            i {
                font-size: 30px;
            }
        }
    }
    
    .about {
        margin: 72px 0;

        .container {
            display: grid;
            grid-template-columns: 1fr;
            gap: 72px;

            @include media(get-bp(desktop)) {
                gap: 40px;
                grid-template-columns: 1fr 600px;
            }

            .about__content {
                h4 {
                    color: get-color(primary);
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 19px;
                    letter-spacing: 3px;
                    margin-bottom: 16px;
                    text-transform: uppercase;
                }

                h3 {
                    color: get-color(dark);
                    font-size: 32px;
                    font-weight: 500;
                    line-height: 38px;
                    letter-spacing: 1px;
                    margin-bottom: 24px;
                }

                p {
                    color: get-color(text);
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 28px;
                    text-align: justified;
                }

                a {
                    border-radius: 100px;
                    border: 1px solid get-color(primary);
                    width: 170px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    padding: 16px 20px;
                    transition: 0.2s;
                    margin-top: 32px;

                    &:hover {
                        opacity: 0.9;
                    }

                    p {
                        color: get-color(dark);
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17px;
                        letter-spacing: 1px;
                        text-align: left;
                    }
                }
            }

            img {
                position: relative;
                width: 100%;
                height: 335px;
                border-radius: 2px;
                object-fit: cover;

                @include media(get-bp(tablet-portrait)) {
                    height: 100%;
                }
            }
        }
    }

    .services {
        margin: 72px 0;

        h4 {
            color: get-color(primary);
            font-size: 16px;
            font-weight: 600;
            line-height: 16.8px;
            letter-spacing: 3px;
            margin-bottom: 40px;
        }

        .tab-container {
            display: grid;
            grid-template-columns: 1fr;

            @include media(get-bp(tablet-portrait)) {
                grid-template-columns: 325px 1fr;
                gap: 50px;
            }

            p {
                font-size: 20px;
            }

            .tab-header {
                display: flex;
                flex-wrap: nowrap;
                margin-bottom: 14px;
                overflow: auto;
                padding-bottom: 10px;

                @include media(get-bp(tablet-portrait)) {
                    margin-bottom: 0;
                    flex-direction: column;
                }

                .tab-header-item {
                    background-color: transparent;
                    color: get-color(title);
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 29px;
                    transition: 0.2s;
                    margin: 0;
                    padding: 26px;
                    border-left: none;
                    border-bottom: 1px solid rgba(177, 155, 125, 0.5);

                    @include media(get-bp(tablet-portrait)) {
                        border-left: 1px solid rgba(177, 155, 125, 0.5);
                        padding: 20px 0 20px 26px;
                        border-bottom: none;
                    }

                    &.is-active {
                        border-left: none;
                        font-weight: 500;
                        border-bottom: 3px solid rgba(179, 157, 125, 1);

                        @include media(get-bp(tablet-portrait)) {
                            border-bottom: none;
                            border-left: 3px solid rgba(179, 157, 125, 1);
                        }
                    }
                }
            }

            .tab-content {
                border: none;

                a {
                    border-radius: 100px;
                    border: 1px solid get-color(primary);
                    width: 170px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    padding: 16px 20px;
                    transition: 0.2s;
                    margin-top: 32px;

                    &:hover {
                        opacity: 0.9;
                    }

                    p {
                        color: get-color(dark);
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17px;
                        letter-spacing: 1px;
                        text-align: left;
                    }
                }
            }
        }
    }

    .question {
        position: relative;
        background-color: rgba(245, 245, 245, 1);

        .container {
            border-top: 1px solid rgba(177, 155, 125, 0.5);
            padding-top: 72px;
            padding-bottom: 72px;
        }

        .question__block {
            position: relative;
            margin-bottom: 32px;
            width: 100%;

            @include media(get-bp(desktop)) {
                width: 300px;
                position: absolute;
                top: 72px;
            }

            h4 {
                color: get-color(title);
                font-size: 32px;
                font-weight: 400;
                line-height: 35px;
                margin-bottom: 24px;

                strong {
                    font-weight: bold;
                }
            }

            p {
                color: get-color(text);
                font-size: 20px;
                font-weight: 400;
                line-height: 30px;
            }
        }

        form {
            .form-group {
                width: 100%;
                margin-left: auto;
                margin-bottom: 16px;

                @include media(get-bp(desktop)) {
                    width: 760px;
                }

                .form-control {
                    border: 1px solid rgba(213, 213, 213, 0.7);
                    border-radius: 8px;
                    color: get-color(text);
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 27px;
                    padding: 16px 24px;
                }

                textarea {
                    resize: none;
                    border: 1px solid rgba(213, 213, 213, 0.7);
                    border-radius: 8px;
                    color: get-color(text);
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 27px;
                    padding: 16px 24px;
                }
            }

            .from-divider {
                width: 100%;
                margin-left: auto;
                display: flex;
                flex-direction: column;
                gap: 16px;

                @include media(get-bp(desktop)) {
                    flex-direction: row;
                    width: 760px;
                }
            }

            .form-block {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                position: relative;
                flex-direction: column-reverse;
                gap: 16px;

                @include media(get-bp(desktop)) {
                    align-items: flex-end;
                    flex-direction: row;
                    top: -26px;
                }

                .button-forms {
                    width: 128px;
                    height: 48px;
                    border: 1px solid get-color(primary);
                    border-radius: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 16px;
                    position: relative;

                    &:hover {
                        opacity: 0.9;
                    }

                    input {
                        width: 100%;
                        height: 100%;
                        background-color: transparent;
                        z-index: 2;
                        position: relative;
                        border: none;
                        padding: 0;
                    }

                    p {
                        color: get-color(title);
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        position: absolute;
                        left: 18px;
                        top: 15px;
                    }

                    svg {
                        position: absolute;
                        right: 18px;
                    }
                }

                .form-checkbox {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    gap: 8px;

                    @include media(get-bp(desktop)) {
                        width: 760px;
                    }
                }
            }
        }
    }
}
