.page-404{
    .page-404{
        height: 720px;
        position: relative;

        >img {
            height: 100%;
            width: 100%;
            display: block;
            object-fit: cover;
        }

        .container{
            height: 100%;
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;

            .block__404{
                display: flex;
                text-align: center;
                flex-direction: column;
                align-items: center;
                width: 100%;
            }

            img {
                width: 394px;
                height: 100%;
                display: block;
                object-fit: contain;
                opacity: 0.9;
            }
        }

        h3{
            color: get-color(primary);
            font-size: 24px;
            font-weight: 600;
            line-height: 28.8px;
            letter-spacing: 4px;
            margin: 72px 0 40px;
        }
        
        a{
            display: flex;
            align-items: center;
            gap: 16px;
            border: 1px solid get-color(primary);
            border-radius: 100px;
            padding: 12px 16px;
            transition: .2s;

            &:hover{
                opacity: .9;
            }
            
            p{
                color: get-color(light);
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 1px;
                margin: 0;
            }
        }
    }
}