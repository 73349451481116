.page-faq {
    .faq {
        margin: 72px 0;
    }

    .accordion-list {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .accordion {
        border: 1px solid get-color(primary);
        border-radius: 6px;
        padding: 32px 24px;
        background-color: transparent;
        margin: 0;
    }

    .accordion__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid get-color(primary);
        padding-bottom: 16px;
        margin-bottom: 32px;

        .accordion__block {
            display: grid;
            grid-template-columns: 32px 1fr;
            gap: 16px;
            align-items: center;

            .accoridon-image {
                height: 32px;
                width: 32px;
                border-radius: 100px;
                background-color: get-color(primary);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            p {
                color: get-color(dark);
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 1px;
                text-align: left;

            }
        }

        i {
            color: get-color(primary);
        }
    }
    
    .accordion__content {
        display: block;
        overflow: hidden;
        height: 55px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        &.is-active {
            height: 100%;
            overflow: visible;
        }

        p {
            color: get-color(text);
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            text-align: justify;

            strong {
                font-weight: 600;
            }
        }
    }
}
