.page-work-us {
    .contact {
        padding: 6rem 0;

        @include media (get-bp(desktop)) {
            padding: 8rem 0;
        }

        .container {
            @include media (get-bp(desktop)) {
                display: grid;
                grid-template-columns: auto 340px;
                grid-column-gap: 60px;
                align-items: flex-start;
            }
        }
    }

    .contact__form {
        p {
            margin-bottom: 30px;

            @include media (get-bp(desktop)) {
                margin-bottom: 0;
                font-size: 20px;
            }
        }

        .alert-success {
            font-weight: 600;
        }

        form {
            margin-bottom: 30px;

            @include media (get-bp(desktop)) {
                margin-bottom: 0;
                margin-top: 30px;
            }
        }

        .columns {
            @include media (get-bp(tablet-landscape)) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: flex-start;
                grid-column-gap: 20px;
            }
        }

        .field {
            margin-bottom: 20px;

            span {
                width: 100%;
            }

            input[type="text"],
            input[type="email"],
            select {
                background-color: get-color(light);
                width: 100%;
                height: 40px;
                border-radius: 6px;
                padding: 0 1rem;
                outline: none;
                font-size: 16px;
                border: 1px solid rgba(0,0,0,0.2);
            }

            textarea {
                width: 100%;
                height: 150px;
                border-radius: 6px;
                padding: 1rem;
                outline: none;
                font-size: 16px;
                border: 1px solid rgba(0,0,0,0.2);
                resize: none;
            }

            label a {
                text-decoration: underline;
            }
        }

        button[type="submit"] {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            padding: 1rem 2rem;
            border-radius: 24px;
            border: 1px solid get-color(primary);
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            transition: 400ms;
            background-color: get-color(light);
            margin-bottom: 20px;

            &:hover {
                background-color: get-color(primary);
                color: get-color(light);

                i {
                    color: get-color(light);
                }
            }

            i {
                margin-left: 10px;
                font-size: 14px;
                margin-top: 1px;
                color: get-color(primary);
            }
        }
    }

    .curriculum--field {
        p {
            font-size: 13px;
            margin: 0;
        }

        .field__curriculum {
            position: relative;
            width: 100%;
            height: 40px;
            border-radius: 6px;
            border: 1px solid rgba(0,0,0,0.2);
            padding: 0 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            input {
                position: absolute;
                width: 100%;
                height: 40px;
                border-radius: 6px;
                left: 0;
                top: 0;
                opacity: 0;

                &:hover {
                    cursor: pointer;
                }
            }

            label {
                font-size: 14px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 40ch;
            }
        }
    }

    .values {
        padding: 2rem;
        border: 1px solid get-color(primary);
        border-radius: 6px;
    }

    .values__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid get-color(primary);

        h2 {
            margin: 0;
            margin-left: 10px;
            font-weight: 500;
            font-size: 20px;
        }
    }

    .values__items {
        p {
            @include media (get-bp(desktop)) {
                font-size: 20px;
            }
        }
    }
}
