.page-about{
    .about__text{
        padding: 72px 0;

        p{
            font-size: 20px;
            font-weight: 400;
            color: get-color(text);
        }

        img{
            float: right;
            width: 100%;
            height: 448px;
            object-fit: cover;
            display: block;
            margin: 32px 0;

            @include media(get-bp(desktop)){
                margin: 0 0 32px 32px;
                width: 607px;
            }
        }
    }

    .values{
        margin-bottom: 72px;

        .accordion{
            background-color: get-color(light);
            border: 1px solid get-color(primary);
            border-radius: 6px;
            padding: 32px 24px;
            height: max-content;
        }

        .accordion__header{
            display: flex;
            align-items: center;
            justify-content: space-between;

            i{
                font-size: 20px;
                color: get-color(primary);
            }
        }

        .accordion__content{
            margin-top: 16px;
            padding-top: 24px;
            border-top: 1px solid get-color(primary);

            p{
                font-size: 20px;
                font-weight: 400;
            }
        }
    }

    .values__head{
        display: flex;
        align-items: center;

        p{
            font-size: 20px;
            font-weight: 400;
            color: get-color(title-black);
        }
    }

    .values__icon{
        background-color: get-color(primary);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
    }

    .values__grid{
        display: grid;
        grid-row-gap: 32px;

        @include media(get-bp(desktop)){
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 32px;
        }
    }
}