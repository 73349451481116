.page-specialized-advocacy {
    .description {
        padding: 6rem 0;

        @include media (get-bp(desktop)) {
            padding: 8rem 0;
        }

        img {
            display: block;
            width: 100%;
            height: 400px;
            object-fit: cover;
            margin-bottom: 30px;
            border-radius: 6px;

            @include media (get-bp(desktop)) {
                margin-left: 40px;
                width: 600px;
                height: 500px;
                margin-bottom: 0;
                float: right;
            }
        }

        p {
            width: 100%;
            margin-bottom: 20px;
            line-height: 28px;
            text-align: justify;

            @include media (get-bp(desktop)) {
                font-size: 20px;
            }

            strong, b {
                margin-bottom: 0;
            }
        }

        strong, b {
            font-weight: 600;
            margin-bottom: 20px;
            display: inline-block;

            @include media (get-bp(desktop)) {
                font-size: 20px;
            }
        }
    }
}
