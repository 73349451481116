.page-origin {
    .description {
        padding: 6rem 0;

        @include media (get-bp(desktop)) {
            padding: 8rem 0;
        }

        img {
            display: block;
            width: 100%;
            height: 400px;
            object-fit: cover;
            margin-bottom: 30px;
            border-radius: 6px;

            @include media (get-bp(desktop)) {
                &:first-child{
                    margin-left: 40px;
                    width: 600px;
                    margin-bottom: 0;
                    height: 588px;
                    float: right;
                }
                
                margin-right: 40px;
                width: 600px;
                margin-bottom: 0;
                height: 588px;
                float: left;
            }
        }

        p {
            width: 100%;
            margin-bottom: 20px;
            line-height: 28px;
            text-align: justify;

            @include media (get-bp(desktop)) {
                font-size: 20px;
            }

            strong, b {
                display: unset;
                margin-bottom: 0;
            }
        }

        strong, b {
            font-weight: 600;
            margin-bottom: 20px;
            display: inline-block;

            @include media (get-bp(desktop)) {
                font-size: 20px;
            }
        }
    }
}
