.phrases {
    background-color: rgba(245, 245, 245, 1);
    padding: 6rem 0;
    
    .phrases__slider {
        overflow: hidden;
        position: relative;
    
        .phrases-button-next,
        .phrases-button-prev {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: get-color(primary);
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;
            z-index: 10;
            display: none;

            @include media(get-bp(desktop)){
                display: flex;
            }
    
            i {
                font-size: 30px;
            }
        }
    
        .phrases-button-next {
            right: 0;
        }
    
        .phrases-button-prev {
            left: 0;
        }
    }

    .swiper-slide {
        h2 {
            text-align: center;
            font-weight: 600;
            font-size: 32px;
            color: get-color(dark);
            font-style: italic;
            

            @include media(get-bp(desktop)){
                max-width: 1000px;
                margin: 0 auto 20px;
            }
        }

        p {
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: get-color(primary);
        }
    }
}