.whatsapp-button{
    z-index: 99;
    width: 48px;
    height: 48px;
    background-color: rgba(17, 57, 61, 1);
    position: fixed;
    right: 32px;
    bottom: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px 12px 12px 4px;
    box-shadow: 1px 1px 8px rgb(0, 0, 0);
        
    svg{
        transition: .3s;
        
        &:hover{
            transform: rotate(15deg);
        }
    }
}