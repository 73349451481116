.footer-container{
    background-color: get-color(secondary);
    padding-top: 40px;
    padding-bottom: 40px;
    color: get-color(light);

    .container {
        @include media (get-bp(desktop)) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }
}

.footer-container__row {
    width: 100%;

    &:first-of-type {
        @include media (get-bp(desktop)) {
            display: flex;
            flex-direction: column;
        }
    }

    &:last-of-type {
        padding-top: 32px;
        border-top: 1px solid rgba(249, 250, 251, 0.1);
    }
}

.footer-container__first-column {
    display: flex;
    justify-content: space-between;
    padding-bottom: 18px;
    border-bottom: 1px solid rgba(249, 250, 251, 0.1);
    margin-bottom: 24px;
}

.footer-logo {
    img {
        display: block;
        width: 100%;
        height: 40px;
        max-width: 127px;
        object-fit: cover;
    }
}

.footer-container__second-column {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 24px;

    @include media (get-bp(desktop)) {
        flex-direction: row;
    }

    .second__content {
        display: grid;
        grid-template-columns: 1fr;
        gap: 32px;
        align-items: center;

        @include media (get-bp(desktop)) {
            grid-template-columns: 104px 1fr;
        }

        img {
            width: 100%;
            max-width: 104px;
            height: 100%;
            object-fit: cover;
            display: block;
        }

        p {
            color: get-color(light);
            font-size: 16px;
            font-weight: 400;
            line-height: 25px;
            text-align: justify;
            
            @include media (get-bp(desktop)) {
                width: 500px;
            }
            
            span {
                font-weight: 600;
                font-size: 18px;
            }
        }
    }

    .second__support{
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include media (get-bp(desktop)) {
            margin-bottom: 0;
        }

        h3 {
            color: get-color(light);
            font-size: 16px;
            font-weight: 600;
            line-height: 25px;
            margin: 0;      
        }

        .support__content {
            display: flex;
            gap: 18px;

            @include media (get-bp(desktop)) {
                flex-direction: row;
            }

            img {
                height: 62px;
                width: 62px;
                object-fit: cover;
                display: block
            }
        }
    }
}

.footer-container__copyright {
    @include media (get-bp(tablet-portrait)) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    @include media (get-bp(desktop)) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }

    p {
        font-size: 14px;
        color: get-color(light);
        margin-bottom: 15px;

        @include media (get-bp(desktop)) {
            margin-bottom: 0;
        }

        a {
            text-decoration: underline;
        }
    }

    img {
        display: block;
        width: 100%;
        max-width: 60px;
        filter: brightness(0) invert(1);
    }
}