.act{
    background-color: get-color(bg-grey);
    padding: 72px 0;
    overflow: hidden;
}

.act__content{
    position: relative;
}

.act__image{
    margin-bottom: 32px;

    @include media(get-bp(desktop)){
        position: absolute;
        top: 0;
        left: -200px;
    }

    img{
        width: 100%;
        height: 100%;
    }
}

.act__text{
    @include media(get-bp(desktop)){
        margin-left: 472px;
    }

    p{
        font-size: 20px;
    }

    >a{
        display: flex;
        align-items: center;
        width: max-content;
        padding: 16px;
        border: 1px solid get-color(primary);
        margin-top: 32px;
        border-radius: 100px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        transition: 400ms;

        &:hover{
            color: get-color(light);
            background-color: get-color(primary);

            img{
                filter: brightness(0) invert(1);
            }
        }

        img{
            margin-left: 16px;
            transition: 400ms;
        }
    }
}

.act__head{
    margin-bottom: 24px;

    span{
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        color: get-color(primary);
        letter-spacing: 3px;
    }

    h3{
        margin-top: 16px;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 32px;
        letter-spacing: 1px;
    }
}